<template>
  <div class="lable-container">
    <a class="inline-image of-16x16">
      <img :src="getImgUrl(icon)" />
    </a>
    <span class="" style="font-weight: 700;">{{ lableName }} </span>
     <b-tooltip v-if="isTruncated" type="is-white" :label="orglableValue">
    <span>{{ lableValue }}</span> 
    </b-tooltip>
    <span v-else>{{ lableValue }}</span> 

    <span v-if="hasCopyValue">
                      <button class="button button-new is-small is-light"
                        style="margin-left: 12px; background-color: white; padding-top: 1px; padding-bottom: 6px;"
                        @click="copyToClipboard(orglableValue, 'id')" >
                        <span class="icon is-small" style="margin-top: 8px; padding: 1px;">
                          <img v-if="isIDCopied" src="../assets/icon_svg/tick.svg" />
                          <img v-else src="../assets/icon_svg/copy.svg" />
                        </span>
                      </button> 
                    </span>
  </div>
</template>

<script>
export default {
  props: {
    lableName: {
      type: String,
      default: null,
    },
    orglableValue: {
      type: String,
    },
    lableValue: {
      default: "NA",
    },
    icon: {
      type: String,
      default: null,
    },
    isTruncated:{
      type: Boolean,
      default: false
    },
    hasCopyValue : {
      type : Boolean,
      default : false
    }
  },

  data() {
    return {
      isCopied: false,
      isIDCopied: false,
      deleting: false,
    }
  },
 
  methods: {
    getImgUrl(img) {
      return require("../../src/assets/icon_svg/" + img);
    },
    copyToClipboard(data, type) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(data).then(() => {
          if (type == 'wid') {
            console.log("wid copied:", data);
            this.isCopied = true;
            setTimeout(() => {
              this.isCopied = false;
            }, 2000);
          } else if (type == 'id') {
            console.log("id copied:", data);
            this.isIDCopied = true;
            setTimeout(() => {
              this.isIDCopied = false;
            }, 2000);
          }
        });
      }
    },
  },
  
};
</script>
<style lang="scss">
.lable-container{
    align-items: center;
    display: flex;
    gap: 7px;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #ededed;
    background-color: white;
}
</style>
