<template>
  <div>
  <!-- + info +{{ info }}--------------------- +details+ {{ details }}----------+allinfo+{{
      allinfo
    }}---------------+filterinfo+{{ filteredinfo }} ---------+getUserDetails+{{
      $store.getters.getUserDetails
    }} -->
    <div
      v-if="filteredinfo.length > 0 && info && details && allinfo"
      class="fontsizeglobaldiv"
    >
      <!-- <div class="columns">
        <div class="column"><span>DETAILS : </span> {{ info.display_name }}</div>
        <div class="column has-text-right">
          Workflow Instance
        </div>
      </div> -->
      <!-- <div class="" style="margin-bottom:20px; margin-top:20px;"><span class="title is-4">Expiry Details</span></div> -->

      <div class="columns marginb coundown-container">
        <div class="column">
          <div class="center time-holder-right">
            <b-tooltip label="Time Elapsed">
              <div class="center">
                <figure class="image is-32x32">
                  <img src="../assets/icon_svg/time-elapsed.svg" />
                </figure>
              </div>
              <div class="timer-container">
                <div class="center">
                  <span class="details-title">{{
                    info.entry_time | time_elapsed_age
                  }}</span>
                </div>
                <div class="has-text-centered subtitle is-6">
                  <span>Time Elapsed</span>
                </div>
              </div>
            </b-tooltip>
          </div>
        </div>

        <div class="column">
          <div class="center middle-time-holder">
            <b-tooltip label="Timeout">
              <div class="center">
                <figure class="image is-32x32">
                  <img src="../assets/icon_svg/time-out.svg" />
                </figure>
              </div>
              <div class="timer-container">
                <div class="center details-title">
                  {{ filteredinfo[0].expires | calendar_time }}
                </div>
                <div class="has-text-centered subtitle is-6">
                  <span>Timeout</span>
                </div>
              </div>
            </b-tooltip>
          </div>
        </div>
        <div class="column">
          <div class="center time-holder-left">
            <b-tooltip label="Time Remaining ">
              <div class="center">
                <figure class="image is-32x32">
                  <img src="../assets/icon_svg/time-remaining.svg" />
                </figure>
              </div>
              <div class="timer-container">
                <div class="center">
                  <span class="details-title">
                    <span class="has-text-danger">{{
                      filteredinfo[0].expires | remaining_time
                    }}</span>
                  </span>
                </div>
                <div class="has-text-centered subtitle is-6">
                  <span>Time Remaining </span>
                </div>
              </div>
            </b-tooltip>
          </div>
        </div>
      </div>

      <div class="columns marginb">
        <div class="column box-rounded">
          <div class="">
            <div class="columns">
              <div class="column">
                <span class="container-title">Participant Information</span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="bcontainer">
              <div class="has-text-centered ">
                <span class="">
                  {{ info.meta.initiator }}
                </span>
              </div>
              <div class="has-text-centered">
                <span class="tag is-rounded is-newaccent">Initiator</span>
              </div>
            </div>
            </div>
            <div class="column">
              <div class="bcontainer">
              <div class="has-text-centered ">
                <span class="">
                  {{ info.lastTransaction.user || "NA" }}
                </span>
              </div>
              <div class="has-text-centered">
                <span class="tag is-rounded is-newaccent">Previous</span>
              </div>
              </div>
            </div>
            <div class="column ">
              <div class="bcontainer">
              <div class="has-text-centered ">
                <span class="">
                  {{ filteredinfo[0].pending_on }}
                </span>
              </div>
              <div class="has-text-centered">
                <span class="tag is-rounded is-newaccent">Current</span>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns marginb">
        <div class="column box-rounded">
          <div class="">
            <div class="columns">
              <div class="column">
                <span class="container-title">Status Information</span>
              </div>
              <div class="column has-text-right">
                <span
                  :class="['tag', 'is-rounded', setTagColor(filteredinfo[0])]"
                >
                  {{ filteredinfo[0].activity_status }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="center">
                <div
                  class="center count-box box-vr-gradiant-blue has-text-centered"
                >
                  <span class="pending-stats">{{
                    filteredinfo[0].pending_on == "You" ? "Yes" : "No"
                  }}</span>
                </div>
              </div>
              <div class="center">
                <span class="font-details"> Pending on You </span>
              </div>
            </div>
            <div class="column">
              <div class="center">
                <div
                  class="center count-box box-vr-gradiant-blue has-text-centered"
                >
                  <span class="pending-stats">{{
                    filteredinfo[0].timeout_status != "NA" &&
                    filteredinfo[0].pending_on == "You"
                      ? "Yes"
                      : "No"
                  }}</span>
                </div>
              </div>
              <div class="center">
                <span class="font-details"> Timeout on You </span>
              </div>
            </div>
            <div class="column">
              <div class="center">
                <div
                  class="center count-box box-vr-gradiant-blue has-text-centered"
                >
                  <span class="pending-stats">{{
                    filteredinfo[0].dormant_status
                  }}</span>
                </div>
              </div>
              <div class="center">
                <span class="font-details"> Urgency Type </span>
              </div>
            </div>
            <div class="column">
              <div class="center">
                <div
                  class="center count-box box-vr-gradiant-blue has-text-centered"
                >
                  <span class="pending-stats">
                    {{
                      filteredinfo[0].pending_on != "None" &&
                      filteredinfo[0].pending_on != "You"
                        ? "Yes"
                        : "No"
                    }}
                  </span>
                </div>
              </div>
              <div class="center">
                <span class="font-details"> Pending in Pipeline </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="" style="margin-bottom: 20px; margin-top: 65px"></div>
    <span class="box-border-title">Status Information</span> -->
    <!-- <div class="gray-box grey-box-padding">
      <div class="columns">
        <div class="column">
          <span class="details-title">Pending on You : </span
          ><span
            :class="[
              'tag',
              'is-medium',
              { 'is-danger': filteredinfo[0].pending_on == 'You' },
              { 'is-rajivsgreen': filteredinfo[0].pending_on != 'You' },
            ]"
            >{{ filteredinfo[0].pending_on == "You" ? "Yes" : "No" }}</span
          >
        </div>
        <div class="column">
          <span class="details-title">Timeout on You : </span>
          <span
            :class="[
              'tag',
              'is-medium',
              filteredinfo[0].timeout_status != 'NA' &&
              filteredinfo[0].pending_on == 'You'
                ? 'is-danger'
                : 'is-rajivsgreen',
            ]"
          >
            {{
              filteredinfo[0].timeout_status != "NA" &&
              filteredinfo[0].pending_on == "You"
                ? "Yes"
                : "No"
            }}
          </span>
        </div>
        <div class="column">
          <span class="details-title">Urgency Type : </span
          ><span
            :class="[
              'tag',
              'is-medium',
              getTagtype(
                filteredinfo[0].timeout_status,
                filteredinfo[0].pending_on
              ),
            ]"
          >
            {{ filteredinfo[0].dormant_status }}
          </span>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <span class="details-title">Pending in Pipeline : </span>
          <span
            :class="[
              'tag',
              'is-medium',
              filteredinfo[0].pending_on != 'None' &&
              filteredinfo[0].pending_on != 'You'
                ? 'is-danger'
                : 'is-rajivsgreen',
            ]"
          >
            {{
              filteredinfo[0].pending_on != "None" &&
              filteredinfo[0].pending_on != "You"
                ? "Yes"
                : "No"
            }}
          </span>
        </div>
        <div class="column">
          <span class="details-title">Timeout in Pipeline : </span>
          <span
            :class="[
              'tag',
              'is-medium',
              filteredinfo[0].timeout_status != 'NA' &&
              filteredinfo[0].pending_on != 'You'
                ? 'is-danger'
                : 'is-rajivsgreen',
            ]"
          >
            {{
              filteredinfo[0].timeout_status != "NA" &&
              filteredinfo[0].pending_on != "You"
                ? "Yes"
                : "No"
            }}
          </span>
        </div>
        <div class="column">
          <span class="details-title">Workflow Status : </span>
          <span :class="['tag', 'is-medium', setTagColor(filteredinfo[0])]">
            {{ filteredinfo[0].activity_status }}
          </span>
        </div>
      </div>
      <div class="columns"></div>
    </div> -->
    <div class="columns box-rounded">
      <div class="" style="width:100%">
        <div class="columns">
          <div class="column">
            <span class="container-title">Workflow Information</span>
          </div>
        </div>
        <div class="columns">
          <div class="column text-lable-container">
            <justLable
              lableName="Form :"
              :lableValue="info.form_name"
              icon="form.svg"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
            v-if="info.type"
              lableName="Type :"
              :lableValue="info.type"
              icon="lasttrnx.svg"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
              lableName="Bundle :"
              :lableValue="info.bundle_name"
              icon="bundle.svg"
            >
            </justLable>
          </div>
        </div>
        <div class="columns">
          <div class="column text-lable-container">
            <justLable
              lableName="Publisher :"
              :lableValue="info.author | title_case"
              icon="publish.svg"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
              lableName="ID : "
              :lableValue="info.workflowId"
              icon="workflow instace.svg"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
              :isTruncated="true"
              lableName="Age :"
              :orglableValue="info.meta.creation_time | time_elapsed_age"
              :lableValue="doTrancate(info.meta.creation_time ,16, '...', 'time_elapsed_age')"
              icon="age.svg"
            >
            </justLable>
          </div>
        </div>
        <div class="columns">
          <div class="column text-lable-container">
            <justLable
              lableName="Previous State :"
              :lableValue="info.lastTransaction.state"
              icon="pre-state.svg"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
              lableName="Current State :"
              :lableValue="info.current_state"
              icon="workflow.svg"
            >
            </justLable>
          </div>
        </div>

        <div class="columns">
          <div class="column is-7 text-lable-container">
            <justLable
              lableName="Last Transaction Id :"
              :orglableValue="info.lastTransaction.txid"
              :lableValue="doTrancate(info.lastTransaction.txid || 'NA', 21, '...')"
              icon="last_trax.svg"
              :hasCopyValue="true"
            >
            </justLable>
          </div>
          <div class="column text-lable-container">
            <justLable
              lableName="Last Transaction Id Number :"
              :lableValue="allinfo.transactions.length"
              icon="lasttrax.svg"
            >
            </justLable>
          </div>
        </div>

        <div class="columns">
          
        </div>
      </div>
    </div>
    <div class="" style="margin-bottom: 20px; margin-top: 65px"></div>

    <div style="height: 200px">
      <div class="columns box-rounded" style="margin-bottom: 100px">
        <div class="column">
          <div class="columns">
            <span class="container-title">Role information</span>
          </div>

          <div class="columns">
            <div class="column">
              <p class="is-size-6">
                <span
                  v-for="i in details.data.roles"
                  class="tag is-newaccent is-rounded is-medium"
                  style="margin-right: 10px"
                  >{{ i }}</span
                >
              </p>
              <p v-if="!details.data.roles">No Roles!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import justLable from "./justLable.vue";

export default {
  props: {
    details: Object,
    info: Object,
    filteredinfo: Array,
    formName: String,
  },
  data() {
    return {};
  },
  components: {
    InputText,
    justLable,
  },
  computed: {
    allinfo() {
      console.log("this", this.$store.getters.getWorkFlowInfo);
      return this.$store.getters.getWorkFlowInfo;
    },
  },
  methods: {
    getTagtype(timeOutOnMe, pendingOnMe) {
      if (timeOutOnMe != "NA") {
        return "is-danger";
      } else if ((timeOutOnMe || timeOutOnMe == "NA") && pendingOnMe == "You") {
        return "is-warning";
      } else {
        return "is-rajivsgreen";
      }
    },
    setTagColor(itemData) {
      let classString = "";
      if (itemData.activity_status == "Continuing") {
        classString = "tag-border-red";
      }
      if (itemData.activity_status == "Completed") {
        classString = "tag-border-green";
      }
      if (itemData.activity_status == "Finalized") {
        classString = "tag-border-bule";
      }

      return classString;
    },
    doTrancate(string, length, suffix, filterOn = '') {
      console.log("filter",filterOn)
      if(filterOn != ''){
        let filterdVal = this.$options.filters[filterOn](string);
        if (filterdVal.length <= length) {
          return filterdVal;
        } else {
          let truncateString =  filterdVal.slice(0, length) + suffix;
          return truncateString
        }
      }else{
        if (string.length <= length) {
        return string;
        } else {
        return string.slice(0, length) + suffix;
        }
      }
    },
  },
  watch: {},
};
</script>
